<template>
  <div class="image-container">
  <div class="head-container">
  <h1> 404 </h1>

  </div>
    <div class="image-wrapper">
        <button v-if="imageLoaded" class="center-button">
          <a href="/">CARRY ME BACK HOME</a>
        </button>
        <img :src="backgroundImageSource" alt="Background Image" class="background-image" @load="onImageLoad"/>
        <img :src="overlayImageSource" alt="Overlay Image" @click="changeOverlayImage" id="overlayimg" :class="{ shake: isShaking }"/>
        <img v-show="false" :src="overlayAngryImageSource" alt="Overlay Image">
    </div>
    <div class="footer-container">
        <h1>Looks like you and Carl are lost. </h1>
        <h2>Click the button to get back home. </h2>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      backgroundImageSource: "https://i.imgur.com/ezuTVKA.png",
      overlayImageSource: "https://i.imgur.com/qoANZqH.png",
      overlayAngryImageSource: "https://i.imgur.com/Jvsr7f8.png",
      clickCount: 0,
      interval: null,
      isShaking: false,
      imageLoaded: false
    };
  },

  methods: {
    changeOverlayImage() {
      this.clickCount++;
      if (this.clickCount % 3 === 0) {
        this.changeImage();
      }
    },

    changeImage() {
      const img = document.getElementById("overlayimg");
      img.src = this.overlayAngryImageSource;
      this.isShaking = true;

      setTimeout(() => {
        this.isShaking = false;
        img.src = this.overlayImageSource;
      }, 500);
    },

    onImageLoad() {
      this.imageLoaded = true;
    }
  }
};
</script>

<style scoped>

@keyframes shake {
  0% { transform: translate(0, 0); }
  10%, 30%, 50%, 70%, 90% { transform: translate(-5px, -5px); }
  20%, 40%, 60%, 80% { transform: translate(5px, 5px); }
  100% { transform: translate(0, 0); }
}

.image-container {
  display: block;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  margin: 0 10%; /* Add left and right margins */
  position: relative;
}

.head-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: 18vh;
      text-align: center;
      padding-bottom: 1rem;
    }

.footer-container{
display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-bottom: 1rem;
}

.footer-container h1{
font-size: 2.8vw;
}

.footer-container h2{
font-size: 1.9vw;
color: grey;
}

.head-container h1 {
      margin: 0;
      font-size: 6vw;
      font-weight: bold;
    }

.image-wrapper {
justify-content: center;
align-items: center;
  position: relative;
  display: flex;
}

.background-image {
  display: block;
  max-width: 100%;
  height: auto;
  z-index: 1;
}

.overlay-image {
  position: absolute;
  bottom: 1%;
  right: 1%;
  width: 11%;
  height: auto;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
}

#overlayimg {
position: absolute;
  bottom: 1%;
  right: 1%;
  width: 11%;
  height: auto;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
}

.center-button {
  position: absolute;
  top: 50%;
  left: 49%;
  transform: translate(-50%, -50%);
  padding: 0.5vw 1vw;
  font-size: 1.2vw;
  background-color: #1385b7;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  word-wrap: break-word;
  text-align: center;
  width: 34%;
  z-index: 2;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
}

.center-button:hover {
  transform: translate(-50%, -50%) scaleX(1.05) scaleY(1.2);
}

.center-button a {
  color: white;
  font-weight: bold;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
}


.shake {
  animation: shake 0.5s ease infinite alternate;
}

</style>
